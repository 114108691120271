import { TableHeadFilterOption } from "@sellernote/_shared-for-admin/src/hooks/common/useTableHeadFilter";

import {
  FreightType,
  Incoterms,
  VolumeSizeUnit,
  WeightUnit,
} from "../../types/common/common";
import {
  AdminBidApplyAirBasicFare,
  AdminBidApplyConsolidationBasicFare,
  AdminBidApplyFclBasicFare,
  AdminBidApplyLclBasicFare,
  AdminBidApplyOceanTicketBasicFare,
  BidCopyStatus,
} from "../../types/forwarding/adminBid";
import { BidServiceType, ContainerType } from "../../types/forwarding/bid";

import { INCOTERMS_OPTION_LIST } from "./import";

const ADMIN_BID_CONTAINER_TYPE_OPTION_LIST: {
  label: string;
  value: ContainerType;
}[] = [
  { label: "20DRY", value: "20DRY" },
  { label: "40DRY", value: "40DRY" },
  { label: "40HQ", value: "40HQ" },
  { label: "20RF", value: "20RF" },
  { label: "40RF", value: "40RF" },
  { label: "20OT", value: "20OT" },
  { label: "40OT", value: "40OT" },
  { label: "20OT(OH)", value: "20OT(OH)" },
  { label: "40OT(OH)", value: "40OT(OH)" },
  { label: "20FR", value: "20FR" },
  { label: "40FR", value: "40FR" },
  { label: "20FR(OH)", value: "20FR(OH)" },
  { label: "40FR(OH)", value: "40FR(OH)" },
  { label: "20FR(OW)", value: "20FR(OW)" },
  { label: "40FR(OW)", value: "40FR(OW)" },
  { label: "20FR(OWH)", value: "20FR(OWH)" },
  { label: "40FR(OWH)", value: "40FR(OWH)" },
];

const ADMIN_BID_WAREHOUSE_OPTION_LIST = [
  { label: "위해", value: 16 },
  { label: "상해", value: 12 },
  { label: "광저우", value: 14 },
  { label: "이우", value: 13 },
  { label: "심천", value: 15 },
];

const ADMIN_BID_PRODUCTS_INFO_BASIC_FORM = {
  isDangerous: false,
  isDouble: false,
  name: "",
  packingType: "pallets",
  quantity: 0,
  unitSupply: 0,
  weight: 0,
  weightUnit: "KG" as WeightUnit,
  height: 0,
  horizontal: 0,
  vertical: 0,
  volumeUnit: "CM" as VolumeSizeUnit,
};

const ADMIN_BID_SERVICE_TYPE_OPTION_LIST: TableHeadFilterOption<BidServiceType>[] =
  [
    {
      label: "일반 의뢰",
      value: "general",
    },
    {
      label: "오션티켓",
      value: "oceanTicket",
    },
    {
      label: "특송",
      value: "consolidation",
    },
  ];

const ADMIN_BID_APPLY_FCL_BASIC_FARE: Record<
  AdminBidApplyFclBasicFare,
  number
> = {
  oceanFreight: 1,
  bafCaf: 1,
  lss: 1,
  totalAdditionalFee: 1,
  thc: 1,
  wfg: 1,
  docFee: 1,
  doFee: 1,
  hc: 1,
  ccc: 1,
  shuttleFee: 1,
  cfsCharge: 1,
  tc: 1,
  ftaCertificateAgencyFee: 1,
  customsFee: 1,
  VATFee: 1,
  inspectionFee: 1,
};

const ADMIN_BID_APPLY_LCL_BASIC_FARE: Record<
  AdminBidApplyLclBasicFare,
  number
> = {
  oceanFreight: 1,
  bafCaf: 1,
  lss: 1,
  totalAdditionalFee: 1,
  thc: 1,
  wfg: 1,
  docFee: 1,
  doFee: 1,
  hc: 1,
  ccc: 1,
  shuttleFee: 1,
  cfsCharge: 1,
  tc: 1,
  ftaCertificateAgencyFee: 1,
  customsFee: 1,
  VATFee: 1,
  inspectionFee: 1,
};

const ADMIN_BID_APPLY_AIR_BASIC_FARE: Record<
  AdminBidApplyAirBasicFare,
  number
> = {
  airFreight: 1,
  thc: 1,
  docFee: 1,
  hc: 1,
  tc: 1,
  ftaCertificateAgencyFee: 1,
  customsFee: 1,
  VATFee: 1,
  inspectionFee: 1,
};

const ADMIN_BID_APPLY_OCEANTICKET_SG_BASIC_FARE: Record<
  AdminBidApplyOceanTicketBasicFare,
  number
> = {
  oceanPerFee: 1,
  tc: 1,
  basicRate: 120,
  dutyTaxFee: 1,
  insuranceFee: 1,
  ftaCertificateAgencyFee: 50,
  customsFee: 1,
  VATFee: 1,
  inspectionFee: 1,
};

const ADMIN_BID_APPLY_OCEANTICKET_BASIC_FARE: Record<
  AdminBidApplyOceanTicketBasicFare,
  number
> = {
  oceanPerFee: 1,
  tc: 1,
  basicRate: 120000,
  dutyTaxFee: 1,
  inspectionFee: 1,
  insuranceFee: 1,
  ftaCertificateAgencyFee: 50,
  customsFee: 1,
  VATFee: 1,
};

const ADMIN_BID_APPLY_CONSOLIDATION_BASiC_FARE: Record<
  AdminBidApplyConsolidationBasicFare,
  number
> = {
  oceanFreight: 1,
  docFee: 35000,
  tc: 1,
  ftaCertificateAgencyFee: 50,
  customsFee: 1,
  VATFee: 1,
  inspectionFee: 1,
};

const ADMIN_BID_APPLY_CURRENCY_OPTION_LIST = [
  {
    label: "USD",
    value: "USD",
  },
  {
    label: "EUR",
    value: "EUR",
  },
  {
    label: "CNY",
    value: "CNY",
  },
  {
    label: "GBP",
    value: "GBP",
  },
  {
    label: "KRW",
    value: "KRW",
  },
  {
    label: "SGD",
    value: "SGD",
  },
];

const ADMIN_BID_FREIGHT_TYPE_OPTION_LIST: TableHeadFilterOption<FreightType>[] =
  [
    {
      label: "FCL",
      value: "FCL",
    },
    {
      label: "LCL",
      value: "LCL",
    },
    {
      label: "AIR",
      value: "AIR",
    },
  ];

const ADMIN_BID_INCOTERMS_OPTION_LIST: TableHeadFilterOption<Incoterms>[] =
  INCOTERMS_OPTION_LIST;

const ADMIN_BID_COPY_STATUS: {
  name: BidCopyStatus;
  label: string;
}[] = [
  { name: "committed", label: "의뢰 받음" },
  { name: "estimating", label: "견적 산출 중" },
  { name: "waiting", label: "사용자 선택 대기 중" },
  { name: "waitingForExporterInfo", label: "수출자 정보 입력 대기 중" },
  { name: "beforeContactPartner", label: "수출자 컨택 전" },
  { name: "contactingPartner", label: "수출자 컨택 중" },
];

const ADMIN_EXPORT_SHIPMENT_COPY_STATUS: {
  name: BidCopyStatus;
  label: string;
}[] = [
  { name: "committed", label: "의뢰 받음" },
  { name: "estimating", label: "견적 산출 중" },
  { name: "waiting", label: "사용자 선택 대기 중" },
  { name: "waitingFreight", label: "화물 준비 중" },
];

// 견적 확정 시 접안항이 있는 항구 모음
const ADMIN_BID_QUOTATION_ANOTHER_COMMENT_PORT_ID = [
  {
    name: "인천항",
    id: 9,
  },
  {
    name: "부산항",
    id: 1,
  },
  {
    name: "부산신항",
    id: 11,
  },
  {
    name: "인천신항",
    id: 336,
  },
];

const ADMIN_BID_SCHEDULE_STATUS_OPTION_LIST = [
  {
    label: "출항 준비 중",
    value: "scheduling",
  },
  {
    label: "공 컨테이너 반출",
    value: "containerCarryOut",
  },
  {
    label: "현지 화물 픽업 완료",
    value: "containerPickup",
  },
  {
    label: "터미널 반입 완료",
    value: "gateIn",
  },
  {
    label: "선적 완료",
    value: "loaded",
  },
  {
    label: "출항완료",
    value: "moving",
  },
];

const ADMIN_EXPORT_STATUS_OPTION_LIST = [
  {
    label: "화물 준비 중",
    value: "waitingFreight",
  },
  {
    label: "출항 준비 중",
    value: "scheduling",
  },
  {
    label: "공 컨테이너 반출",
    value: "containerCarryOut",
  },
  {
    label: "국내 픽업 완료",
    value: "containerPickup",
  },
  {
    label: "국내 CFS 픽업완료",
    value: "domesticImportCFS",
  },
  {
    label: "국내 터미널 반입 완료",
    value: "gateIn",
  },
  {
    label: "선적 완료",
    value: "loaded",
  },
  {
    label: "출항완료",
    value: "moving",
  },
  {
    label: "입항완료",
    value: "portEntryAndPrepareCustoms",
  },
  {
    label: "통관 완료",
    value: "completeCustoms",
  },
  {
    label: "내륙 운송 중",
    value: "delivering",
  },
];

const SHIPMENT_TYPE_OPTION_LIST = [
  {
    label: "전체 상태",
    value: "all",
  },
  { label: "수입", value: "importation" },
  { label: "수출", value: "exportation" },
];
export {
  ADMIN_BID_CONTAINER_TYPE_OPTION_LIST,
  ADMIN_BID_WAREHOUSE_OPTION_LIST,
  ADMIN_BID_PRODUCTS_INFO_BASIC_FORM,
  ADMIN_BID_SERVICE_TYPE_OPTION_LIST,
  ADMIN_BID_APPLY_FCL_BASIC_FARE,
  ADMIN_BID_APPLY_LCL_BASIC_FARE,
  ADMIN_BID_APPLY_AIR_BASIC_FARE,
  ADMIN_BID_APPLY_OCEANTICKET_SG_BASIC_FARE,
  ADMIN_BID_APPLY_OCEANTICKET_BASIC_FARE,
  ADMIN_BID_APPLY_CONSOLIDATION_BASiC_FARE,
  ADMIN_BID_APPLY_CURRENCY_OPTION_LIST,
  ADMIN_BID_FREIGHT_TYPE_OPTION_LIST,
  ADMIN_BID_INCOTERMS_OPTION_LIST,
  ADMIN_BID_COPY_STATUS,
  ADMIN_BID_QUOTATION_ANOTHER_COMMENT_PORT_ID,
  ADMIN_BID_SCHEDULE_STATUS_OPTION_LIST,
  ADMIN_EXPORT_STATUS_OPTION_LIST,
  ADMIN_EXPORT_SHIPMENT_COPY_STATUS,
  SHIPMENT_TYPE_OPTION_LIST,
};
