import { useAtomValue } from "jotai";

import AUTH_QUERY from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_AUTH_QUERY";
import LoginForAdmin from "@sellernote/_shared-for-forwarding-admin/src/components/LoginForAdmin";
import { FORWARDING_ADMIN_AUTH_ATOMS } from "@sellernote/_shared-for-forwarding-admin/src/jotaiStates/auth";

export default function Login() {
  const loggedIn = useAtomValue(
    FORWARDING_ADMIN_AUTH_ATOMS.PARTNER_ADMIN_LOGGED_IN
  );

  const {
    mutate: login,
    ResponseHandler: ResponseHandlerOfLogin,
    isLoading,
  } = AUTH_QUERY.usePartnerAdminLogin();

  return (
    <LoginForAdmin
      title="파트너 로그인"
      login={({ id, password }) => {
        login({ accountId: id, password: password });
      }}
      isLoadingOfLogin={isLoading}
      isLoggedIn={loggedIn}
      ResponseHandlerOfLogin={ResponseHandlerOfLogin}
    />
  );
}
