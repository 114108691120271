import { useAppQueryWithQueryKeyFactory } from "../../../services/query";

import {
  GET_CUSTOMS_ADMIN_BID_DETAIL_REQ,
  GET_CUSTOMS_ADMIN_BID_DETAIL_RES,
  GET_CUSTOMS_ADMIN_BID_LIST_REQ,
  GET_CUSTOMS_ADMIN_BID_LIST_RES,
} from "../../../api-interfaces/shipda-api/admin/customsAdminBid";

export const CUSTOMS_ADMIN_BID_QUERY_KEY_GEN = {
  all: () => [{ scope: "forwarding/CUSTOMS_ADMIN_BID_QUERY" }] as const,

  getCustomsBidList: (params: GET_CUSTOMS_ADMIN_BID_LIST_REQ) =>
    [
      {
        ...CUSTOMS_ADMIN_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "customsBidList",
      },
    ] as const,

  getCustomsBidDetail: (params: GET_CUSTOMS_ADMIN_BID_DETAIL_REQ) =>
    [
      {
        ...CUSTOMS_ADMIN_BID_QUERY_KEY_GEN.all()[0],
        ...params,
        entity: "customsBidDetail",
      },
    ] as const,
};

function useGetCustomsBidList({
  params,
  onSuccess,
  enabled,
}: {
  params: GET_CUSTOMS_ADMIN_BID_LIST_REQ;
  onSuccess?: (res: GET_CUSTOMS_ADMIN_BID_LIST_RES) => void;
  enabled?: boolean;
}) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof CUSTOMS_ADMIN_BID_QUERY_KEY_GEN.getCustomsBidList>,
    GET_CUSTOMS_ADMIN_BID_LIST_RES
  >({
    queryKey: CUSTOMS_ADMIN_BID_QUERY_KEY_GEN.getCustomsBidList(params),
    requestOptions: {
      method: "get",
      path: "/customs/bid",
      apiType: "ShipdaAdminDefaultNew",
      params,
    },
    keepPreviousData: true,
    enabled,
    onSuccess,
  });

  return { ...queryResult };
}

function useGetCustomsBidDetail(params: GET_CUSTOMS_ADMIN_BID_DETAIL_REQ) {
  const queryResult = useAppQueryWithQueryKeyFactory<
    ReturnType<typeof CUSTOMS_ADMIN_BID_QUERY_KEY_GEN.getCustomsBidDetail>,
    GET_CUSTOMS_ADMIN_BID_DETAIL_RES
  >({
    queryKey: CUSTOMS_ADMIN_BID_QUERY_KEY_GEN.getCustomsBidDetail(params),
    requestOptions: {
      method: "get",
      path: `/customs/bid/detail/${params.bidId}`,
      apiType: "ShipdaAdminDefaultNew",
    },
  });

  return { ...queryResult };
}

const CUSTOMS_ADMIN_BID_QUERY = {
  useGetCustomsBidList,
  useGetCustomsBidDetail,
};

export default CUSTOMS_ADMIN_BID_QUERY;
