import { GET_ADMIN_BID_DETAIL_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/admin/adminBid";
import Tabs from "@sellernote/_shared-for-forwarding-admin/src/components/Tabs";
import CargoInfo from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/CargoInfo";
import QuotationsUser from "@sellernote/_shared-for-forwarding-admin/src/pageContainers/bid/detail/QuotationsUser";

const BidDetailTabs = ({
  bidDetail,
}: {
  bidDetail: GET_ADMIN_BID_DETAIL_RES;
}) => {
  if (bidDetail.serviceType === "consolidation") {
    return (
      <Tabs
        defaultTabPanelValue={0}
        tabsData={[
          {
            tabLabel: "확정 견적",
            tabPanelItem: bidDetail.quotationsUser.length > 0 && (
              <QuotationsUser shipmentDetail={bidDetail} />
            ),
          },
        ]}
      />
    );
  }
  return (
    <Tabs
      defaultTabPanelValue={0}
      tabsData={[
        {
          tabLabel: "화물정보 및 상세",
          tabPanelItem: <CargoInfo bidDetail={bidDetail} />,
        },
        {
          tabLabel: "확정 견적",
          tabPanelItem: bidDetail.quotationsUser.length > 0 && (
            <QuotationsUser shipmentDetail={bidDetail} />
          ),
        },
      ]}
    />
  );
};

export default BidDetailTabs;
