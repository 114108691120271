import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { GET_BID_TRACKING_SHIP_RES } from "@sellernote/_shared/src/api-interfaces/shipda-api/bid";
import { SvgTypeIconInfo } from "@sellernote/_shared/src/components/googleMap/GoogleMapWithCluster";
import { APP_NAME } from "@sellernote/_shared/src/constants";
import {
  Coordinate,
  FreightType,
} from "@sellernote/_shared/src/types/common/common";
import { BidPortMap } from "@sellernote/_shared/src/types/forwarding/bid";
import { isEmptyObjectOrArray } from "@sellernote/_shared/src/utils/common/etc";
import { findNearestIndex } from "@sellernote/_shared/src/utils/common/googleMap";
import {
  createInfoContainer,
  createSvgMarker,
} from "@sellernote/_shared/src/utils/forwarding/map";

import { ShipdaCurrentLanguage } from "../../i18n/i18nForShipda";

export default function useShipmentMapMarkerInfoList({
  trackingShipResult,
  freightType,
}: {
  trackingShipResult: GET_BID_TRACKING_SHIP_RES | undefined;
  freightType: FreightType | undefined;
}) {
  const { t } = useTranslation(["bid"]);

  function getBidMapPortCoordinate(port?: BidPortMap): Coordinate | undefined {
    if (!port || !port.lat || !port.lng) {
      return;
    }

    return {
      lat: port.lat,
      lng: port.lng,
      name:
        ShipdaCurrentLanguage.currentLanguage === "ko"
          ? port.name || port.nameEN
          : port.nameEN,
    };
  }

  /** 현재 운송 진행중 여부 */
  const hasMoving =
    trackingShipResult &&
    trackingShipResult.pin &&
    Boolean(trackingShipResult.pin.lat) &&
    Boolean(trackingShipResult.pin.lng);

  /** 맵의 센터 포지션 */
  const centerPosition = useMemo(() => {
    if (hasMoving) {
      return {
        lat: trackingShipResult.pin.lat,
        lng: trackingShipResult.pin.lng,
      };
    }

    return { lat: 35, lng: 125 };
  }, [hasMoving, trackingShipResult]);

  const polylineData = useMemo(() => {
    if (!trackingShipResult) return;

    if (isEmptyObjectOrArray(trackingShipResult.routes)) return;

    return trackingShipResult.routes.flatMap((routeArray) =>
      routeArray.map((port) => ({ lat: port.lat, lng: port.lng }))
    );
  }, [trackingShipResult]);

  const splitIndex = useMemo(() => {
    if (!trackingShipResult) return;

    if (!polylineData) return;

    return findNearestIndex({
      pinLat: trackingShipResult?.pin.lat,
      pinLng: trackingShipResult?.pin.lng,
      routes: polylineData,
    });
  }, [polylineData, trackingShipResult]);

  /** 출발지 위도, 경도를 구하는 함수 */
  const getDeparture = useCallback((): Coordinate | undefined => {
    const targetPort = trackingShipResult?.startPort;

    if (!targetPort) return undefined;

    return getBidMapPortCoordinate(targetPort);
  }, [trackingShipResult?.startPort]);

  /** 도착지 위도, 경도를 구하는 함수 */
  const getDestination = useCallback((): Coordinate | undefined => {
    const targetPort = trackingShipResult?.endPort;

    if (!targetPort) return undefined;

    return getBidMapPortCoordinate(targetPort);
  }, [trackingShipResult?.endPort]);

  /** 경유지를 구하는 함수 */
  const getStopovers = useCallback((): Coordinate[] | undefined => {
    if (!trackingShipResult) return;

    if (isEmptyObjectOrArray(trackingShipResult)) return;

    if (!trackingShipResult.pin) return;

    // 항적(폴리라인)이 있다면 마커의 위치를 실선 폴리라인의 마지막 좌표로 설정
    if (polylineData && splitIndex) {
      return [polylineData[splitIndex]];
    }

    return [
      {
        lat: trackingShipResult.pin.lat,
        lng: trackingShipResult.pin.lng,
      },
    ];
  }, [polylineData, splitIndex, trackingShipResult]);

  /** 현재 이동중인 화물 위도, 경도 */
  const shipPosition = useMemo((): Coordinate | undefined => {
    const stopovers = getStopovers();
    if (!stopovers?.length) {
      return getDeparture();
    }

    return stopovers[0];
  }, [getDeparture, getStopovers]);

  const markerInfoList = useMemo(() => {
    if (!trackingShipResult) return;

    const parser = new DOMParser();

    const departure = getDeparture();
    const destination = getDestination();

    const infoIconImgSrc = (() => {
      if (freightType === "AIR") {
        return APP_NAME === "shipda-kr"
          ? "/assets/images/mypage/bid/marker-info-icon-plane.svg"
          : "/images/trello/marker-info-icon-plane.svg";
      }

      return APP_NAME === "shipda-kr"
        ? "/assets/images/mypage/bid/marker-info-icon-ship.svg"
        : "/images/trello/marker-info-icon-ship.svg";
    })();

    const waypointIconImgSrc = (() => {
      return APP_NAME === "shipda-kr"
        ? "/assets/images/mypage/bid/ellipsis-solid.svg"
        : "/images/trello/ellipsis-solid.svg";
    })();

    const departureInfo = createInfoContainer(
      "departure",
      t("bid:운송관리_공통_TRACKING_MAP_출발지"),
      departure?.name,
      infoIconImgSrc
    );

    const destinationInfo = createInfoContainer(
      "destination",
      t("bid:운송관리_공통_TRACKING_MAP_도착지"),
      destination?.name,
      infoIconImgSrc
    );

    const shipSvg = `<svg width="12" height="18" viewBox="0 0 12 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path id="Polygon 4" d="M6 0L12 18L6 15L0 18L6 0Z" fill="#D65481"/>
    </svg>
    `;

    const stopShipSvg = `<svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="18" height="24" fill="#F5F5F5"/>
    <g id="tracking Map (15345)">
    <rect width="2098" height="1008" transform="translate(-1126 -406)" fill="#FFE7E7"/>
    <g id="Group 353">
    <path id="Subtract" fill-rule="evenodd" clip-rule="evenodd" d="M18 9.21012C18 4.13177 13.9625 0 9.00004 0C4.03756 0 0 4.13177 0 9.21012C0 11.3012 0.670344 13.2733 1.93821 14.9143L9.00004 24L16.0599 14.9162C17.3297 13.2733 18 11.3012 18 9.21012ZM9.00039 12.5999C10.9886 12.5999 12.6004 10.9881 12.6004 8.9999C12.6004 7.01168 10.9886 5.3999 9.00039 5.3999C7.01217 5.3999 5.40039 7.01168 5.40039 8.9999C5.40039 10.9881 7.01217 12.5999 9.00039 12.5999Z" fill="#D65481"/>
    </g>
    </g>
    </svg>
    `;

    const portSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6" r="5" fill="#DD6E97" stroke="#C03D69" stroke-width="2"/>
    </svg>
    `;

    const waypointSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <circle cx="6" cy="6" r="5" fill="#EFBED2" stroke="#DD6E97" stroke-width="2"/>
    </svg>`;

    const parsedShipSvg = parser.parseFromString(
      trackingShipResult.pin.showVesselIcon ? shipSvg : stopShipSvg,
      "image/svg+xml"
    ).documentElement;

    const parsedDepartureSvg = parser.parseFromString(
      portSvg,
      "image/svg+xml"
    ).documentElement;

    const parsedDestinationPortSvg = parser.parseFromString(
      portSvg,
      "image/svg+xml"
    ).documentElement;

    parsedShipSvg.style.transform = `translate(5%, 55%) rotate(${
      // heading 값이 null일 때가 있음
      trackingShipResult.pin.heading || 0
    }deg)`;
    parsedShipSvg.style.transformOrigin = "center";

    parsedDepartureSvg.style.position = "absolute";
    parsedDepartureSvg.style.transform = "translate(-50%, -50%)";

    parsedDestinationPortSvg.style.position = "absolute";
    parsedDestinationPortSvg.style.transform = "translate(-50%, -50%)";

    // 환적지 정보는 여러개 올 수 있기 때문에 리스트로 표기
    const waypointInfoList = trackingShipResult.waypoints
      .map((waypoint) => [
        // 환적지 정보
        {
          lat: waypoint.lat,
          lng: waypoint.lon,
          markerInfo: createInfoContainer(
            "waypoint",
            "환적지",
            waypoint.name,
            waypointIconImgSrc
          ),
          title: waypoint.name + "-info",
        },
        // 환적지 Marker
        {
          lat: waypoint.lat,
          lng: waypoint.lon,
          title: waypoint.name + "-marker",
          iconInfo: {
            type: "svg",
            svg: createSvgMarker(waypointSvg),
          } as SvgTypeIconInfo,
        },
      ])
      .flat();

    return [
      ...(departure
        ? [
            {
              ...departure,
              iconInfo: {
                type: "svg",
                svg: parsedDepartureSvg,
              } as SvgTypeIconInfo,
              title: "departureIcon",
            },
            {
              ...departure,
              markerInfo: departureInfo,
              title: "departureInfo",
            },
          ]
        : []),

      ...(waypointInfoList.length ? waypointInfoList : []),

      ...(shipPosition?.lat && shipPosition?.lng
        ? [
            {
              ...shipPosition,
              iconInfo: {
                type: "svg",
                svg: parsedShipSvg,
              } as SvgTypeIconInfo,
              title: "shipPosition",
            },
          ]
        : []),

      ...(destination
        ? [
            {
              ...destination,
              iconInfo: {
                type: "svg",
                svg: parsedDestinationPortSvg,
              } as SvgTypeIconInfo,
              title: "destinationIcon",
            },
            {
              ...destination,
              markerInfo: destinationInfo,
              title: "destinationInfo",
            },
          ]
        : []),
    ];
  }, [
    freightType,
    getDeparture,
    getDestination,
    shipPosition,
    t,
    trackingShipResult,
  ]);

  return {
    centerPosition,
    markerInfoList,
  };
}
